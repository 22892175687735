@import "utilities/variables";
@import "utilities/mixins";
/* Welcome to Compass. Use this file to write IE specific override styles.
 * Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" />
 * <![endif]--> */

@include breakpoint(S) {
  .image-cta {
    height: 10em;
  }
}
